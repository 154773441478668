import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import { IconHeart } from '../../components';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  
  // Get the listing ID
  const listingId = listing?.id?.uuid;
  
  // Always show the heart icon
  const showHeartIcon = true;

  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
      {showHeartIcon && (
        <IconHeart
          className={css.galleryHeartIcon}
          listingId={listingId}
        />
      )}
    </section>
  );
};

export default SectionGallery;
